import React, { useState, useEffect, useRef } from 'react'; // React Hooks
import { useNavigate } from 'react-router-dom'; // Correct hook for navigation

// Replace Feather icons with react-icons for web use
import { FaLock, FaEye, FaEyeSlash } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';

// Import CSS styles instead of StyleSheet
import '../styles/WelcomeScreen.css';

const WelcomeScreen = () => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [userPassword, setPassword] = useState('');
  const [userCp, setCP] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const location = useLocation();

  const textInputRef = useRef(null);
  const navigate = useNavigate(); // Change here

  const API_URL = 'https://server.oketudiant.ousoka.sn/api/login';

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async () => {
    if (userCp.trim() === '' || userPassword.trim() === '') {
      alert('Veuillez remplir tous les champs.');
      return;
    }
    try {
      setIsLoading(true);
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_cp: userCp, user_password: userPassword }),
      });
  
      const data = await response.json();
      console.log("Response Data:", data); // Log entire response
  
      if (response.ok) {
        const userData = data.userData;
        if (userData) {
          console.log("User Data Received:", userData); // Log user data

          // Store user data in localStorage
          localStorage.setItem('userData', JSON.stringify(userData)); // Store user data as a string

          navigate('/home', { state: { userData, userPassword } });
        } else {
          console.error("No user data found in response.");
          alert("User data not received from server.");
        }
      } else if (response.status === 401) {
        alert('Le code permanent et le mot de passe ne correspondent pas.');
      } else {
        alert(`Erreur: ${data.error}`);
      }
    } catch (error) {
      console.error('API error:', error);
      alert('Une erreur s\'est produite. Veuillez réessayer plus tard.');
    } finally {
      setIsLoading(false);
    }
  };
  

//   const handleLogin = async () => {navigate('/home')
// };

  return (
    <div className="welcome_signin-container">

      <header className="welcome_header">
        <img src={require('../assets/oketudiant_logo.png')} alt="Logo" className="welcome_logo" />
        <h1 className='welcome_ok'>OKEtudiant</h1>
      </header>

      {/* <h1 className="title">Se Connecter</h1> */}
      <p className="welcome_subtitle">
        Entrer votre code permanent et mot de passe pour vous connecter
      </p>

      <div className="welcome_input-group" onClick={() => textInputRef.current.focus()}>
        <div className="welcome_input-prefix">CP</div>
        <input
          type="text"
          ref={textInputRef}
          placeholder="Code Permanent"
          onChange={e => setCP(e.target.value)}
          className="welcome_input-text"
        />
      </div>

      <div className="welcome_input-group">
        <div className="welcome_input-subcontainer">
          <FaLock size={22} color="#0A8791" style={{ marginRight: 10 }} />
          <input
            type={showPassword ? 'text' : 'password'}
            placeholder="Mot de passe"
            className="welcome_input-text"
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            className="welcome_eye-icon"
            onClick={togglePasswordVisibility}
          >
            {showPassword ? <FaEyeSlash size={22} color="#0A8791" /> : <FaEye size={22} color="#0A8791" style={{marginRight: 0,}} />}
          </button>
        </div>
      </div>

      {errorMessage && <div className="welcome_error-message">{errorMessage}</div>}

      <button className="welcome_signin-button" onClick={handleLogin} disabled={isLoading}>
        {isLoading ? 'Loading...' : 'Se Connecter'}
      </button>
    </div>
  );
};

export default WelcomeScreen;
