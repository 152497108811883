// import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import '../styles/Settings.css';
// import { FaArrowLeft } from 'react-icons/fa'; // Using FontAwesome for icons
// import { useLocation } from 'react-router-dom';
// import axios from 'axios';

// const Settings = () => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const { userData, userPassword } = location.state || {};
//   // State variables to manage form inputs
//   const [currentPassword, setCurrentPassword] = useState('');
//   const [newPassword, setNewPassword] = useState('');
//   const [confirmPassword, setConfirmPassword] = useState('');
//   const [errorMessage, setErrorMessage] = useState('');

//   useEffect(() => {
//     const handleBackPress = () => {
//       navigate(-1); // Go back on browser history
//       return true;
//     };

//     window.addEventListener('popstate', handleBackPress); // Mimic back handler for web
//     return () => window.removeEventListener('popstate', handleBackPress);
//   }, [navigate]);

//   const handlePasswordChange = (e) => {
//     e.preventDefault(); // Prevent the default form submission

//     // Basic validation
//     if (!currentPassword || !newPassword || !confirmPassword) {
//       setErrorMessage('All fields are required.');
//       return;
//     }
//     if (newPassword !== confirmPassword) {
//       setErrorMessage('New password and confirmation do not match.');
//       return;
//     }

//     // Check if the current password matches the user's actual password
//     if (currentPassword !== userPassword) {
//       setErrorMessage('Current password is incorrect.');
//       return;
//     }

//     const Update_password = async () => {
//       try {
    
//         const response = await axios.put('https://server.oketudiant.ousoka.sn:3031/api/update_password', {
//           user_cp: userData?.user_cp,
//           userPassword,
//           newPassword,
//         });
    
//         if (response.status === 200) {
//           console.log('Password updated successfully');
//           alert('Password updated successfully');
//           // setNewValues(response.data.updatedValues); // Update state with new values
//           navigate('/home',  { state: { userData, userPassword } });
//         } else {
//           console.log('Error response from server:', response.data.error);
//           alert(`Erreur: ${response.data.error}`);
//         }
//       } catch (error) {
//         alert('Une erreur s\'est produite lors de la mise à jour du mot de passe. Veuillez réessayer.');
//         console.error('Error submitting update:', error.message);
//       }
//     };

//     console.log("Password change request: ", {
//       currentPassword,
//       newPassword,
//     });
    
//     // Reset form after submission
//     setCurrentPassword('');
//     setNewPassword('');
//     setConfirmPassword('');
//     setErrorMessage(''); // Clear any error messages
//   };

//   return (
//     <div className="awaiting_scroll-container1">
//       {/* Header section */}
//       <div className="awaiting_gradient-header">
//         <div className="awaiting_header-content">
//           <div className="awaiting_header-row">
//             <div className="awaiting_header-container">
//               <button onClick={() => navigate(-1)} className="awaiting_back-button">
//                 <FaArrowLeft size={40} color="#FFF" />
//               </button>
//               <h1 className="awaiting_header-title">Paramètres</h1>
//             </div>
//           </div>

//           <div className="awaiting_header-extra-space"></div>
//         </div>
//       </div>

//       {/* Body section */}
//       <div className="awaiting_body-container1">
//         <h2 className="awaiting_section-header">Changer le Mot de Passe</h2>

//         <form onSubmit={handlePasswordChange} className="password-change-form">
//           {errorMessage && <p className="error-message">{errorMessage}</p>}
//           <div className="input-group">
//             <label htmlFor="currentPassword">Mot de passe actuel:</label>
//             <input
//               type="password"
//               id="currentPassword"
//               value={currentPassword}
//               onChange={(e) => setCurrentPassword(e.target.value)}
//               required
//             />
//           </div>
//           <div className="input-group">
//             <label htmlFor="newPassword">Nouveau mot de passe:</label>
//             <input
//               type="password"
//               id="newPassword"
//               value={newPassword}
//               onChange={(e) => setNewPassword(e.target.value)}
//               required
//             />
//           </div>
//           <div className="input-group">
//             <label htmlFor="confirmPassword">Confirmer le nouveau mot de passe:</label>
//             <input
//               type="password"
//               id="confirmPassword"
//               value={confirmPassword}
//               onChange={(e) => setConfirmPassword(e.target.value)}
//               required
//             />
//           </div>
//           <button type="submit" className="submit-button">Changer le Mot de Passe</button>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default Settings;


// Settings.js (React)
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/Settings.css';
import { FaArrowLeft } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

const Settings = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userData, userPassword } = location.state || {};

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const handleBackPress = () => {
      navigate(-1);
      return true;
    };

    window.addEventListener('popstate', handleBackPress);
    return () => window.removeEventListener('popstate', handleBackPress);
  }, [navigate]);

  const handlePasswordChange = async (e) => {
    e.preventDefault();

    if (!currentPassword || !newPassword || !confirmPassword) {
      setErrorMessage('All fields are required.');
      return;
    }
    if (newPassword !== confirmPassword) {
      setErrorMessage('New password and confirmation do not match.');
      return;
    }
    if (currentPassword !== userPassword) {
      setErrorMessage('Current password is incorrect.');
      return;
    }

    try {
      const response = await axios.put('https://server.oketudiant.ousoka.sn/api/update_password', {
        user_cp: userData?.user_cp,
        userPassword: currentPassword,
        newPassword,
      });

      if (response.status === 200) {
        console.log('Password updated successfully');
        alert('Password updated successfully');
        navigate('/home', { state: { userData, userPassword: newPassword } });
      } else {
        alert(`Erreur: ${response.data.error}`);
      }
    } catch (error) {
      alert("Une erreur s'est produite lors de la mise à jour du mot de passe. Veuillez réessayer.");
      console.error('Error submitting update:', error.message);
    }

    setCurrentPassword('');
    setNewPassword('');
    setConfirmPassword('');
    setErrorMessage('');
  };

  return (
    <div className="awaiting_scroll-container1">
      <div className="awaiting_gradient-header">
        <div className="awaiting_header-content">
          <div className="awaiting_header-row">
            <div className="awaiting_header-container">
              <button onClick={() => navigate(-1)} className="awaiting_back-button">
                <FaArrowLeft size={40} color="#FFF" />
              </button>
              <h1 className="awaiting_header-title">Paramètres</h1>
            </div>
          </div>
          <div className="awaiting_header-extra-space"></div>
        </div>
      </div>

      <div className="awaiting_body-container1">
        <h2 className="awaiting_section-header">Changer le Mot de Passe</h2>

        <form onSubmit={handlePasswordChange} className="password-change-form">
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <div className="input-group">
            <label htmlFor="currentPassword">Mot de passe actuel:</label>
            <input
              type="password"
              id="currentPassword"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="newPassword">Nouveau mot de passe:</label>
            <input
              type="password"
              id="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="confirmPassword">Confirmer le nouveau mot de passe:</label>
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="submit-button">Changer le Mot de Passe</button>
        </form>
      </div>
    </div>
  );
};

export default Settings;
